<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          <v-card-title>
            <span class="">{{
              (editedIndex === -1 ? $t("add") : $t("edit")) +
                " " +
                $t("users.user")
            }}</span>
          </v-card-title>
        </v-toolbar>
        <v-card-subtitle>
          {{ $t("users.userName") + " : " }}
          <v-chip label color="success">{{ editedItem.userName }}</v-chip>
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  persistent-hint
                  v-model="editedItem.testParticularList"
                  :rules="[rules.required]"
                  :label="$t('tests.particularName')"
                  multiple
                  :items="filterdPartecular"
                  item-text="text"
                  item-value="value"
                  :menu-props="{
                    maxHeight: '90%',
                  }"
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-text-field
                            v-model="searchParticular"
                            outlined
                            dense
                            hide-details
                            :label="$t('search')"
                          ></v-text-field>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            editedItem.testParticularList &&
                            editedItem.testParticularList.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                        >
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t("selectAll") }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            class="white--text"
            @click="save"
            :disabled="!valid"
            :min-width="100"
          >
            <v-icon>mdi-content-save-outline</v-icon>
            {{ $t("save") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="onClose">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: [
    "onClose",
    "dialog",
    "editedItem",
    "editedIndex",
    "resetValidation",
    "testParticular",
  ],
  data() {
    return {
      valid: true,
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
      searchParticular: "",
    };
  },
  watch: {
    resetValidation() {
      this.resetForm();
    },
  },
  computed: {
    likesAllFruit() {
      return (
        this.editedItem.testParticularList &&
        this.editedItem.testParticularList.length === this.testParticular.length
      );
    },
    likesSomeFruit() {
      return (
        this.editedItem.testParticularList &&
        this.editedItem.testParticularList.length > 0 &&
        !this.likesAllFruit
      );
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    filterdPartecular() {
      var roles = Object.assign([], this.testParticular);
      if (this.searchParticular) {
        return roles.filter((item) => {
          return this.searchParticular
            .toLowerCase()
            .split(" ")
            .every((v) => item.text.toLowerCase().includes(v));
        });
      } else {
        return roles;
      }
    },
  },
  methods: {
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit == true) {
          this.editedItem.testParticularList = [];
        } else {
          this.editedItem.testParticularList = this.testParticular.map(
            (obj) => obj.value
          );
        }
      });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        this.$axios
          .put(
            "TestParticularUser/Save?id=" + this.editedItem.accountGuid,
            this.editedItem.testParticularList
          )
          .then((response) => {
            if (response.data.status == "Successful") {
              this.$toast(this.$t("operationAccomplishedSuccessfully"));
              this.onClose();
              this.$emit("refreshTable");
            } else {
              this.$toast.error(this.$t("error." + response.data.message));
            }
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            this.$emit("refreshTable");
            console.log(e);
          });
      }
    },
  },
};
</script>

<style></style>
