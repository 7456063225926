<template>
  <v-container fluid>
    <v-card outlined class="pa-5 mt-3">
      <!-- <v-radio-group v-model="particularType" row>
        <v-radio
          v-for="type in particularTypes"
          :key="type.value"
          :label="type.text"
          :value="type.value"
        ></v-radio>
      </v-radio-group> -->
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          outlined
          dense
          class="shrink search"
          :class="$vuetify.breakpoint.xsOnly ? 'seachXs' : ''"
          :placeholder="$t('search')"
          :label="$t('search')"
          single-line
          hide-details
        />
        <v-spacer></v-spacer>
        <!-- <v-btn
          v-if="$store.getters.isInRole(72)"
          color="primary"
          dark
          class="mb-2"
          @click="dialog = true"
        >
          {{ $t("add") }}
          <v-icon> mdi-plus </v-icon>
        </v-btn> -->
      </v-toolbar>

      <data-table-component
        :headers="headers"
        :items="testParticularUser"
        :search="search"
        :loading="loading"
      />
    </v-card>

    <Dialog
      :onClose="close"
      :dialog="dialog"
      :editedItem="editedItem"
      :editedIndex="editedIndex"
      :resetValidation="resetValidation"
      :testParticular="testParticular"
      @refreshTable="refreshTable"
    />
    <confirm-dialog
      :openDialog="dialogDelete"
      :onClicked="deleteItemConfirm"
      :onClose="closeDelete"
    />
  </v-container>
</template>
<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import Dialog from "./Dialog.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
export default {
  components: { DataTableComponent, ConfirmDialog, Dialog },
  data() {
    return {
      loading: true,
      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",
      particularType: 0,
      testParticularUser: [],
      testParticular: [],
      users: [],
      editedItem: {},
      defaultItem: {},
      resetValidation: 0,
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
    };
  },

  computed: {
    // particularTypes() {
    //   return [
    //     { text: this.$t("tests.particularTypes.Pathology"), value: 0 },
    //     { text: this.$t("tests.particularTypes.Radiology"), value: 1 },
    //     { text: this.$t("tests.particularTypes.Histopathology"), value: 2 },
    //     { text: this.$t("tests.particularTypes.Audiology"), value: 3 },
    //   ];
    // },
    headers() {
      var list = [
        { text: this.$t("users.userName"), value: "userName" },
        // {
        //   text: this.$t("tests.particularType"),
        //   value: "particularType",
        //   type: "select",
        //   items: this.particularTypes,
        // },
        {
          text: this.$t("tests.particularName"),
          value: "testParticularList",
          type: "multiselect",
          items: this.testParticular,
        },
      ];

      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };

      if (this.$store.getters.isInRole(111)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-pencil",
          text: this.$t("edit"),
          click: this.editItem,
        });
      }
      // if (this.$store.getters.isInRole(74)) {
      //   actions.templates.push({
      //     type: "btn",
      //     icon: "mdi-delete",
      //     text: this.$t("delete"),
      //     click: this.deleteItem,
      //     color: "red darken-2",
      //   });
      // }
      list.push(actions);
      return list;
    },
  },
  created() {
    this.loading = true;
    this.refreshTable();
    this.getParticular();
    this.getUsers();
  },

  watch: {
    dialog(val) {
      this.editedItem.particularType = this.particularType;
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    particularType() {
      this.refreshTable();
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.testParticularUser.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // this.editedItem.particularType = this.particularType;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.testParticularUser.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.testParticularUser[this.editedIndex];
      this.$axios
        .delete("TestParticular/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation += 1;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      this.$axios
        .get("TestParticularUser")
        .then((response) => {
          this.testParticularUser = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getParticular() {
      this.$axios
        .get("TestParticular")
        .then((response) => {
          for (let index = 0; index < response.data.data.length; index++) {
            var element = response.data.data[index];
            this.testParticular.push({
              text: element.particularName,
              value: element.guid,
            });
          }
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getUsers() {
      this.$axios
        .get("Account/GetUsers")
        .then((response) => {
          this.users = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
